import { getDeatil } from './../api'
export default {
  name: "",
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "/center/terminalInspectionRecord",
          name: "terminalInspectionRecord",
          title: "巡检记录"
        },
        {
          path: "/center/terminalInspectionRecord/terminalInspectiondetail",
          name: "terminalInspectiondetail",
          title: "详情"
        }
      ],
      pageLoadFlag: false,
      detilInfo: {}
    }
  },


  mounted() {
    if(this.$route.query.id) {
      let id  = this.$route.query.id
      this.getdetailInfo(id)
    } else {
      this.$message.warning('获取详情失败')
      // this.getdetailInfo('460646868870')
    }
  },
  
  methods:{
    getdetailInfo(id) {
      this.pageLoadFlag = true
      let data =  {
        id:id,
        serviceCode:'findDetailById'
      }
      getDeatil(data).then( res=> {
        if(res.data.returnCode == 100) {
          this.detilInfo = res.data.returnData
          this.pageLoadFlag = false
        } else {
          this.$message.warning(res.data.returnMsg)
          this.pageLoadFlag = false
        }
      })
    },
    // 返回
    goback() {
      window.close()
    }
        
  }
};